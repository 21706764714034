<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
  .msgName {
    width: 100px;
    min-width: 100px;
  }

  .msgLabel {
    height: 35px;
    line-height: 35px;
  }

  .personPic {
    height: 140px;
  }

  .personPic img {
    height: 117px;
    width: 88px;
  }
</style>
<script>
  import Layout from "../../layouts/main";
  import CheckHeader from "@/components/check-header";
  import CheckTableHeader from "@/views/pages/user/checkTable-header";
  import $ from "jquery"
  import changeExam from '@/components/change-exam.vue'
  import {
    getNowExam,
  } from "@/api/admin/exam/examRecord.js"
  import UserInfoCheck from  './components/user-Info-check'

  /**
   * 学历审核
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      CheckTableHeader,
      UserInfoCheck,
      changeExam
    },
    data() {
      return {
        title: "考试报名信息审核  / ",
        title2:"内江师范学院2021年12月考核招聘艺体类工作人员招聘考试",
        subTitle: "切换报名",
        ksbmbh:"",
        ksmc:"",
        items: [{
            text: "用户首页"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        defaultvalue: 1,
        currentpage: 1,
        tableList: [{
            index: 1,
            bkzw: "辅导员",
            name: "王小川",
            csny: "1990-09-09",
            xb: "女",
            xl: "本科",
            xw: "学士",
            sfzh: "510101200112121234",
          },
          {
            index: 2,
            bkzw: "辅导员",
            name: "王小川",
            csny: "1990-09-09",
            xb: "女",
            xl: "本科",
            xw: "学士",
            sfzh: "510101200112121234",
          },
          {
            index: 3,
            bkzw: "辅导员",
            name: "王小川",
            csny: "1990-09-09",
            xb: "女",
            xl: "本科",
            xw: "学士",
            sfzh: "510101200112121234",
          }
        ],
        showmodal: false,
        tableTool: {
          zp: 1,
          shtg: 1,
          shbtg: 1,
          xxsh: 1,
        },
        allData: [{
            label: "照片",
            key: "zp",
          },
          {
            label: "审核通过",
            key: "shtg",
          },
          {
            label: "审核不通过",
            key: "shbtg",
          },
          {
            label: "详细审核",
            key: "xxsh",
          },

        ],
        checkData: ['zp',  'xxsh', ],
      };


    },
    methods: {
      getTool() {
        console.log(this.checkData)
        let check = this.checkData
        let all = this.tableTool
        for (var i in all) {
          if (check.indexOf(i) > -1) {
            this.tableTool[i] = 1
          } else {
            this.tableTool[i] = 0
          }
        }
        this.showmodal = false
      },
      guding() {
        var left = $("#tableDiv").scrollLeft(); //获取滚动的距离
        // if(left>245){return}
        var trs = $("#tableDiv table tr"); //获取表格的所有tr
        var table = $("#tableDiv table");
        let width = table.width()
        let parentWidth = $("#tableDiv table").parent().width();
        let currentWidth = parentWidth + left - width;
        // console.log(currentWidth)
        trs.each(function() { //对每一个tr（每一行）进行处理
          // for(var i=10;i<trs.length,i++){
          //     $(this).children().eq(i).css({"position":"relative","top":"0px","left":left,"z-index":66});
          // }
          // let s = -245
          $(this).children().eq(10).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });
          $(this).children().eq(11).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });
          $(this).children().eq(12).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });
          $(this).children().eq(13).css({
            "position": "relative",
            "top": "0px",
            "left": currentWidth
          });
          
        });
      },
      //获取切换的考试
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      getLastExam() {
        getNowExam().then(res => {
          if (res.status) {
            this.ksbmbh = res.data.ksbmbh
            this.ksmc = res.data.ksmc
          }
        })
      },
    },

    mounted() {
      this.getLastExam()
      this.guding()
      $("#tableDiv").scroll(this.guding);

    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <CheckTableHeader />
            <div class="table-responsive border" id="tableDiv" style="max-width: 100%;">
              <table class="table light-table table-hover table-bordered table-scroll">
                <thead class="thead-light">
                  <tr >
                    <th style="width: 50px"> <input type="checkbox" value /></th>
                    <th style="width: 100px">报考职位</th>
                    <th style="width: 100px">姓名</th>
                    <th style="width: 100px">出生年月 </th>
                    <th style="width: 100px">性别 </th>
                    <th style="width: 100px">学历 </th>
                    <th style="width: 100px">学位 </th>
                    <th style="width: 200px">身份证号 </th>
                    <th style="width: 200px">报考照片 </th>
                    <th style="width: 200px">审核状态 </th>
                    <th style="width:50px" class="guding " v-if="tableTool.zp">照片 </th>
                    <th style="width:90px" class="guding " v-if="tableTool.shtg">审核通过 </th>
                    <th style="width:90px" class="guding " v-if="tableTool.shbtg">不通过审核 </th>
                    <th style="width:90px" class="guding " v-if="tableTool.xxsh">详细审核 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="obj in tableList" :key="obj.index">
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value id="defaultCheck1" />
                        <label class="form-check-label" for="defaultCheck1">{{obj.index}}</label>
                      </div>
                    </td>
                    <td>{{obj.bkzw}}</td>
                    <td>
                      <b-button v-b-modal.person2 variant="link" class="blue-font h30">{{obj.name}}</b-button>
                    </td>
                    <td>{{obj.csny}}</td>
                    <td>{{obj.xb}}</td>
                    <td>{{obj.xl}}</td>
                    <td>{{obj.xw}}</td>
                    <td>{{obj.sfzh}}</td>
                    <td><img src="@/assets/images/person/person1.png" alt="" class="person-img"></td>
                    <td>
                      <div>缴费状态:<span class="text-info">已缴费</span></div>
                      <div>学历核验:<span class="text-info">已核验</span></div>
                      <div>资格审核:<span class="grey-font">锁定中</span></div>

                    </td>
                    <td class="tab-icon light-yellow-bg" v-if="tableTool.zp"><i
                        class="iconfont icon-a-ze-checkedCopy2  text-success"></i>
                    </td>
                    <td class="light-green-bg" v-if="tableTool.shtg"><button type="button"
                        class="btn btn-info h30 ">通过</button></td>
                    <td class="light-pink-bg" v-if="tableTool.shbtg"><button type="button"
                        class="btn btn-danger h30 ">不通过</button></td>
                    <td class="light-blue-bg" v-if="tableTool.xxsh"><button type="button"
                        class="btn btn-secondary h30 ">审核</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination
                  @size-change="handleSizeChange"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="pageData.pageNum"
                  :page-size="pageData.pageSize"
                  layout="total, sizes,jumper, prev, pager, next"
                  :total="pageData.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 表格列数自定义 -->
    <b-modal id="tableSet" v-model="showmodal" centered title="表格自定义" title-class="font-18" size="lg" hide-footer>
      <div style="padding-left: 85px;">
        <el-transfer v-model="checkData" :data="allData" :titles="['所有列', '需要显示的列']"></el-transfer>
      </div>
      <div class="text-center mt-3"> <button type="button" class="btn btn-info mr-2 w-sm h30"
          @click="getTool">确定</button>
        <button type="button" class="btn btn-secondary w-sm h30" @click="showmodal = false"> 取消</button>
      </div>
    </b-modal>

    <changeExam></changeExam>
    <UserInfoCheck ref="userInfoCheck"></UserInfoCheck>
    <!-- 弹窗结束 -->
  </Layout>
</template>
